import { fetchArrivedTime, fetchCtbArrivedTime, fetchCtbStop, fetchStop } from "./fetch";

export async function stopName(
    busNumber: string,
    dir: string,
    seq: string
): Promise<string | null> {
    const data = await fetchStop()
    const findStop = data.data.find(
        (item: any) => item.seq === seq
            && item.route === busNumber
            && item.bound === dir
    ) as { stop: string } | undefined
    return findStop ? findStop.stop : null
}

//九巴
export async function busTime(
    busNumber: string,
    dir: string,
    seq: number
) {
    const stop = await stopName(busNumber, dir, seq + '')
    const data = await fetchArrivedTime(stop as string, busNumber)
    const nowTime = new Date().getTime()
    if (data.data[0].eta) {
        const dataTime = data.data.map((item: any) => {
            const etaTime = new Date(item.eta);
            const hours = etaTime.getHours().toString().padStart(2, '0');
            const minutes = etaTime.getMinutes().toString().padStart(2, '0');
            const remainingMinutes = Math.floor((etaTime.getTime() - nowTime) / (1000 * 60));

            return `${remainingMinutes} 分鐘 (${hours}:${minutes})`;
        })
        return dataTime

    } else {
        return null
    }

}

//城巴
export async function btcBusTime(
    busNumber: string,
    dirGo: string,
    dir: string,
    seq: number
) {
    const data = await fetchCtbStop(busNumber, dir)
    const findStop = data.data.find(
        (item: any) => item.seq === seq
            && item.route === busNumber
            && item.dir === dirGo
    )
    const stop = findStop.stop

    const timeData = await fetchCtbArrivedTime(busNumber, stop)
    const nowTime = new Date().getTime()

    if (timeData.data[0].eta) {
        const dataTime = timeData.data.map((item: any) => {
            const etaTime = new Date(item.eta);
            const hours = etaTime.getHours().toString().padStart(2, '0');
            const minutes = etaTime.getMinutes().toString().padStart(2, '0');
            const remainingMinutes = Math.floor((etaTime.getTime() - nowTime) / (1000 * 60));

            return `${remainingMinutes} 分鐘 (${hours}:${minutes})`;
        })
        return dataTime
    } else {
        return null
    }
}
