import { Link } from 'react-router-dom';
import './Nav.css';

export function Nav() {

    // const goWork = 'เข้างาน'
    // const getOffWork = 'เลิกงาน'
    const mainPage = '<'
    const AllBus = '裕雅苑(全部巴士)'

    return (
        <div className='navBar no-select'>
            <Link to='/'>{mainPage}</Link>
            {/* <Link to='/TCtoST'>{goWork}</Link> */}
            {/* <Link to='/STtoTC'>{getOffWork}</Link> */}
            <Link to='/AllBusForYuNga'>{AllBus}</Link>
        </div>
    )
}