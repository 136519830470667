import { useEffect, useMemo, useState } from "react";
import { btcBusTime, busTime } from "./api/route";
import { TbXboxX } from "react-icons/tb";
import './BusPage.css'

interface BusTimeData {
    item1: string | null;
    item2: string | null;
    item3: string | null;
}

interface BusTimes {
    [key: string]: BusTimeData | null
}

export function AllBusForYuNga() {
    const [timeout, setTimeout] = useState<Date>(new Date());
    const [busTimes, setBusTimes] = useState<BusTimes>({})

    const buses = useMemo(() => [
        { name: 'E23A', seq: 15, dir: 'I', backSeq: 7, isBtc: true },
        { name: 'E32', seq: 7, dir: 'I', backSeq: 7, isBtc: false },
        { name: 'E31', seq: 7, dir: 'I', backSeq: 7, isBtc: false },

        { name: 'E32A', seq: 10, dir: 'I', backSeq: 14, isBtc: false },
        { name: 'E36A', seq: 14, dir: 'I', backSeq: 14, isBtc: false },
        { name: 'E21A', seq: 18, dir: 'I', backSeq: 23, isBtc: true },
        { name: 'E21D', seq: 18, dir: 'I', backSeq: 21, isBtc: true },
        { name: 'E11A', seq: 20, dir: 'I', backSeq: 16, isBtc: true },
        { name: 'E11B', seq: 15, dir: 'I', backSeq: 16, isBtc: true },
    ], []);

    useEffect(() => {
        const fetchData = async () => {
            const fetchPromises = buses.flatMap(async (bus) => {
                try {
                    const inboundFetch = bus.isBtc
                        ? await btcBusTime(bus.name, bus.dir, 'inbound', bus.seq)
                        : await busTime(bus.name, bus.dir, bus.seq);

                    const outboundFetch = bus.isBtc
                        ? await btcBusTime(bus.name, 'O', 'outbound', bus.backSeq)
                        : await busTime(bus.name, 'O', bus.backSeq);

                    return [
                        inboundFetch,
                        outboundFetch,
                    ];
                } catch (e) {
                    console.log(`${bus.name} no data`);
                    return [null, null];
                }
            });

            const results = await Promise.all(fetchPromises);
            const newBusTimes: BusTimes = results.reduce((acc, [inbound, outbound], index) => {
                const bus = buses[index];
                if (Array.isArray(inbound)) {
                    acc[bus.name] = { item1: inbound[0], item2: inbound[1], item3: inbound[2] };
                }
                if (Array.isArray(outbound)) {
                    acc[`${bus.name}Back`] = { item1: outbound[0], item2: outbound[1], item3: outbound[2] };
                }
                return acc;
            }, {} as BusTimes);

            setBusTimes(newBusTimes);
        };

        fetchData();

        const interval = setInterval(() => {
            setTimeout(new Date());
        }, 10000);

        return () => clearInterval(interval);
    }, [timeout, buses]);


    const noBus = <TbXboxX />;
    const busStopGo = '裕雅苑 - 出東涌 (ป้ายรถเมล์ใต้บ้าน - รถเมล์ไปทงชุง)';
    const busStopBack = '轉車站 - 回東涌 (สถานีเปลี่ยนรถ - รถเมล์กลับทงชุง)';
    const faiiGo = `樓下學校旁的巴士站 - 出東涌的巴士 (ป้ายรถเมล์ข้างโรงเรียนใต้ตึก - รถเมล์ไปทงชุง)`;
    const faiiBack = `下班回家的巴士站 (ป้ายรถเมล์สำหรับกลับบ้านหลังเลิกงาน)`;

    return (
        <div className="mainPage">
            <h2>Sukanya - Faii</h2>
            <div className="allBus">
                <div>
                    <div className="carName">{faiiGo}</div>
                    <div className="carName">{buses[0].name}</div>
                    {busTimes[buses[0].name] ? (
                        <div>
                            {busTimes[buses[0].name]?.item1 !== null && <div>{busTimes[buses[0].name]?.item1}</div>}
                            {busTimes[buses[0].name]?.item2 !== null && <div>{busTimes[buses[0].name]?.item2}</div>}
                            {busTimes[buses[0].name]?.item3 !== null && <div>{busTimes[buses[0].name]?.item3}</div>}
                        </div>
                    ) : (
                        <div>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{faiiBack}</div>
                    <div className="carName">{buses[0].name}</div>
                    {busTimes[`${buses[0].name}Back`] ? (
                        <div>
                            {busTimes[`${buses[0].name}Back`]?.item1 !== null && <div>{busTimes[`${buses[0].name}Back`]?.item1}</div>}
                            {busTimes[`${buses[0].name}Back`]?.item2 !== null && <div>{busTimes[`${buses[0].name}Back`]?.item2}</div>}
                            {busTimes[`${buses[0].name}Back`]?.item3 !== null && <div>{busTimes[`${buses[0].name}Back`]?.item3}</div>}
                        </div>
                    ) : (
                        <div>{noBus}</div>
                    )}
                </div>
            </div>

            <h2>For Angus</h2>
            <h2 className="carName">下班</h2>
            <div className="allBus">
                <div>
                    <div className="carName">{buses[1].name}</div>
                    {busTimes[`${buses[1].name}Back`] ? (
                        <div>
                            {busTimes[`${buses[1].name}Back`]?.item1 !== null && <div>{busTimes[`${buses[1].name}Back`]?.item1}</div>}
                            {busTimes[`${buses[1].name}Back`]?.item2 !== null && <div>{busTimes[`${buses[1].name}Back`]?.item2}</div>}
                            {busTimes[`${buses[1].name}Back`]?.item3 !== null && <div>{busTimes[`${buses[1].name}Back`]?.item3}</div>}
                        </div>
                    ) : (
                        <div>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{buses[2].name}</div>
                    {busTimes[`${buses[1].name}Back`] ? (
                        <div>
                            {busTimes[`${buses[2].name}Back`]?.item1 !== null && <div>{busTimes[`${buses[2].name}Back`]?.item1}</div>}
                            {busTimes[`${buses[2].name}Back`]?.item2 !== null && <div>{busTimes[`${buses[2].name}Back`]?.item2}</div>}
                            {busTimes[`${buses[2].name}Back`]?.item3 !== null && <div>{busTimes[`${buses[2].name}Back`]?.item3}</div>}
                        </div>
                    ) : (
                        <div>{noBus}</div>
                    )}
                </div>
            </div>

            <h2>{busStopGo}</h2>
            <div className="allBus">
                {buses.filter(bus => bus.name !== 'E23A' && bus.name !== 'E32' && bus.name !== 'E31')
                    .map(bus => (
                        <div key={bus.name}>
                            <div className="carName">{bus.name}</div>
                            {busTimes[bus.name] ? (
                                <div>
                                    {busTimes[bus.name]?.item1 !== null && <div>{busTimes[bus.name]?.item1}</div>}
                                    {busTimes[bus.name]?.item2 !== null && <div>{busTimes[bus.name]?.item2}</div>}
                                    {busTimes[bus.name]?.item3 !== null && <div>{busTimes[bus.name]?.item3}</div>}
                                </div>
                            ) : (
                                <div>{noBus}</div>
                            )}
                        </div>
                    ))}
            </div>

            <h2>{busStopBack}</h2>
            <div className="allBus">
                {buses.filter(bus => bus.name !== 'E23A' && bus.name !== 'E36A' && bus.name !== 'E32' && bus.name !== 'E31')
                    .map(bus => (
                        <div key={bus.name + 'Back'}>
                            <div className="carName">{bus.name}</div>
                            {busTimes[`${bus.name}Back`] ? (
                                <div>
                                    {busTimes[`${bus.name}Back`]?.item1 !== null && <div>{busTimes[`${bus.name}Back`]?.item1}</div>}
                                    {busTimes[`${bus.name}Back`]?.item2 !== null && <div>{busTimes[`${bus.name}Back`]?.item2}</div>}
                                    {busTimes[`${bus.name}Back`]?.item3 !== null && <div>{busTimes[`${bus.name}Back`]?.item3}</div>}
                                </div>
                            ) : (
                                <div>{noBus}</div>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
}